import React from 'react';
import { string, bool } from 'prop-types';
import loadable from '@loadable/component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faSignIn,
  faCreditCard,
  faGiftCard,
} from '@fortawesome/pro-light-svg-icons';

import { useRegistration, useIconOverRide } from './styles';

const Grid = loadable(() => import('@material-ui/core/Grid'));
const Hidden = loadable(() => import('@material-ui/core/Hidden'));
const Typography = loadable(() => import('@material-ui/core/Typography'));
const List = loadable(() => import('@material-ui/core/List'));
const ListItem = loadable(() => import('@material-ui/core/ListItem'));
const ListItemIcon = loadable(() => import('@material-ui/core/ListItemText'));
const ListItemText = loadable(() => import('@material-ui/core/ListItemText'));

const SquareSignupImage = loadable(() => import('components/square-image/signup'));

const RegistrationIconList = ({ sub = '', small = false }) => {
  const styles = useRegistration();
  const iconOveride = useIconOverRide();

  return (
    <Grid spacing={2}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >

    <Grid item xl={6} lg={8} md={10} sm={12} className={styles.actionsMessageContent}>

      <Grid spacing={2}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Hidden xsDown>
            <Grid item md={4} sm={4}>
              <SquareSignupImage />
            </Grid>
          </Hidden>
          {small ? (
            <Hidden xsDown>
            <Grid item md={8} sm={8} xs={12}>
            <List>
              <ListItem>
                <ListItemIcon classes={iconOveride}>
                  <FontAwesomeIcon icon={faSignIn} size="2x" className={styles.icon}/>
                </ListItemIcon>
                <ListItemText
                  primary={
                  <Typography variant="h6" component="p">
                   Create a login
                  </Typography>
                  }
                  secondary="Email and password or Google, or Apple account"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon classes={iconOveride}>
                  <FontAwesomeIcon icon={faCreditCard} size="2x" className={styles.icon}/>
                </ListItemIcon>
                <ListItemText
                primary={
                  <Typography variant="h6" component="p">
                    Select a subscription
                  </Typography>
                }
                secondary="There's no contract, you can cancel anytime"/>
              </ListItem>
              <ListItem>
                <ListItemIcon classes={iconOveride}>
                  <FontAwesomeIcon icon={faGiftCard} size="2x" className={styles.icon}/>
                </ListItemIcon>
                <ListItemText primary={
                  <Typography variant="h6" component="p">
                   Build your digital loyalty cards
                  </Typography>
                }
                secondary="Build branded digital loyalty cards instantly"/>
              </ListItem>
            </List>
            </Grid>
            </Hidden>
          ) : (
            <Grid item md={8} sm={8} xs={12}>
            <List>
              <ListItem>
                <ListItemIcon classes={iconOveride}>
                  <FontAwesomeIcon icon={faSignIn} size="2x" className={styles.icon}/>
                </ListItemIcon>
                <ListItemText
                  primary={
                  <Typography variant="h6" component="p">
                   Create a login
                  </Typography>
                  }
                  secondary="Email and password or Google, or Apple account"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon classes={iconOveride}>
                  <FontAwesomeIcon icon={faGiftCard} size="2x" className={styles.icon}/>
                </ListItemIcon>
                <ListItemText primary={
                  <Typography variant="h6" component="p">
                   Build your digital loyalty card
                  </Typography>
                }
                secondary="Build branded digital loyalty cards instantly"/>
              </ListItem>
              <ListItem>
                <ListItemIcon classes={iconOveride}>
                  <FontAwesomeIcon icon={faCreditCard} size="2x" className={styles.icon}/>
                </ListItemIcon>
                <ListItemText
                primary={
                  <Typography variant="h6" component="p">
                    Select a subscription
                  </Typography>
                }
                secondary="There's no contract, you can cancel anytime"/>
              </ListItem>
            </List>
            </Grid>
          )}
        </Grid>
      </Grid>

    </Grid>
  );
};

RegistrationIconList.propTypes = {
  sub: string,
  small: bool,
};

export default RegistrationIconList;
